.foot {
	/*
	background: hsla(0, 100%, 10%, .5);
	*/
	background: rgba(255, 255, 255, 0);
	margin-top: 0;
	/*
	z-index: 2;
	*/

	&__nav {

		ul {
			/*
			border: solid #f5f7f9;
			border-width: 3px 0;
			*/
			margin: 1em auto;
			padding: .5em 0;
			text-align: center;
			width: 100%;

			@media (--med) {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				max-width: var(--widthL);
			}
		}

		li {
			padding: .25em 1em;
		}

	}

	&__contact {
		/*
		align-content: flex-start;
		align-items: flex-start;
		*/
		display: flex;
		flex-wrap: wrap;
		margin: 1em auto;
		max-width: var(--widthL);
		justify-content: center;

		&--sec {
			margin: .5em 1em;
			padding: .25em 1em;
			text-align: center;
			min-width: 17.5em;

			@media (--med) {
				flex: 1 0 10em;
				text-align: left;
				min-width: 10em;
				padding: .25em;
			}
		}

		h2 {
			font-weight: 600;
			/*
			padding-bottom: .3em;
			*/
			text-transform: uppercase;
		}

		h2,
		li,
		p {
			color: #024071;
			font-size: .8em;
			margin: 0;
			/*
			padding: .125em 0;
			*/
		}

		a {
			color: currentColor;
		}
	}

	&__social {
		margin: 0 0 .75em;
		padding: 0;
		/*
		text-align: center;
		*/

		li {
			display: inline;
		}

		a {
			/*
			border: 1px solid #333;;
			*/
			border: none;
			display: inline-block;
			margin-right: .75em;
			text-align: center;

			&:focus {
				outline: .25em solid;
				outline-color: #cde;
				outline-offset: .25em;
			}
		}

		.icon {
			border-radius: 25%;
			height: 2.5em;
			padding: .25em;
			fill: #fff;
			stroke: #fff;
			stroke-width: 1;
			stroke-linecap: round;
			stroke-linejoin: round;
			width: 2.5em;

			&__fb {
				background: #3c579a;
			}

			&__tw {
				background: #55aded;
			}

			&__ig {
				background: #111;
				fill: none;
				stroke-width: 2;
			}
		}

	}

	address {
		font-style: normal;
	}

	/*
	p {
		padding: .125em 0;
	}

	.copy {
		font-size: .9em;
	}
	*/
}

.copy {
	font-size: .9em;
}

.up {
	padding: .5em;
	bottom: 0;
	position: fixed;
	right: 0;
	z-index: 30;

	&__rel {
		font-size: .8em;
		padding: 0;
		text-align: right;
	}
}

.svgifrm {
	display: none;
}
