.nav {

	&__list {
		background: #fff;
		box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
		width: 100% !important;
		z-index: 1;

		a {
			border: 1px solid transparent;
			color: var(--color-bluebttn);
			display: block;
			padding: .5em;
			white-space: nowrap;

			&:focus,
			&:hover {
				color: #fff;
				background: var(--color-bluebttn);
			}

			&:focus {
				border-color: #ca6;
				border-style: dashed;
			}
		}

		> li {
			position: relative;
			width: 100%;

			> a {

				font-weight: 600;
				text-transform: uppercase;
				vertical-align: middle;

				/*
				&.tog:after {
					color: #c0c9cc;
					content: "\0020\25BE";

					&:hover {
						color: #fff;
					}
				}
				*/
			}

			@media (--lg) {
				border: 0;
				width: unset;
			}
		}

		@media (--lg) {
			align-content: center;
			align-items: center;
			box-shadow: none;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;

			a {
				padding: .5em 1em;
			}

			> li {

				margin: 0 .125em;

				> a {
					border: 1px solid transparent;
					padding: .5em .5em;
					text-align: center;
				}
			}

			&[aria-hidden="true"] {
				opacity: 1;
				top: unset;
			}
		}
	}

	&__sub {

		min-width: 100%;
		z-index: 1;

		a {
			background: #fff;
			border: 1px solid transparent;
			min-width: 12em;
			padding: .8em .6em .8em 1em;
			text-align: left;
			white-space: nowrap;
		}

		@media (--lg) {
			box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
		}

	}

	&__logout {

		@media (--lg) {

			a {
				background: rgba(0, 0, 0, .5);
			}
		}
	}

	@media (--lg) {
		align-self: flex-end;
		box-shadow: unset;
		margin-left: 3em;
	}

}

.nav__bttn {

	border: 0;
	padding: 0;

	svg  {
		display: block;
		stroke: var(--color-banner);
		stroke-linecap: round;
		stroke-linejoin: round;
		stroke-width: 3;
	}

	&[aria-expanded="true"] {

		/*
		line {
			transform: rotate(45deg) translate(.0625em, -.25em);

			&.beef {
				stroke: transparent;
			}

			&:last-child {
				transform: rotate(-45deg) translate(.0625em, .25em);
				transform-origin: bottom left;
			}
		}
		*/
		line {
			stroke: #ddd;

			&.beef {
				stroke: currentColor;
			}
		}
	}
}

.bcrm {
	background: #fff;
	background: hsla(0, 50%, 100%, .9);

	ul {
		margin: 0 auto;
		max-width: var(--widthL);
		padding: .5em;
	}

	li {
		display: inline-block;
		white-space: nowrap;

		span {
			margin: 0 .5em 0 .25em;
		}
	}

	a[aria-current] {
		border: 0;
		color: #333;
	}
}

/*
 * sidebar navigation on sub-pages
 */

.content {

	&__aside {

		nav {

			ul {
				background: #fcfcfc;
			}

			a {
				border: 1px solid;
				border-color: transparent transparent #eee;
				display: block;
				padding: .5em;

				&:focus,
				&:hover {
					background: #fff;
					border: 1px dashed currentColor;
				}
			}

			/*
			.current {
			*/
			li[aria-current] {
				background: var(--color-bluebttn);
				color: #fff;
				padding: .5em;
				&::before {
					content: "\00BB\0020";
				}
			}
		}
	}
}

.no-js {

	.nav_toggle {
		display: none;
	}
}
