@supports (display: grid) {

	@media (--lg) {

		.content {

			&__article {
				align-content: start;
				display: grid;
				grid-template-columns: repeat(16, 1fr);

				header,
				h1,
				h2,
				h3 {
					grid-column: 1 / 16;
				}

				p,
				ol,
				ul,
				li {
					grid-column: 2 / 16;
				}

				ul {

					&.weather,
					&.content__prod {
						grid-column: 1 / 17;
						margin: 0;
					}

				}

				div,
				section,
				form,
				figure {
					grid-column: 1 / 17;
				}

				.rept__cart {
					grid-column: 2 / 17;
				}

				.js__controls {
					grid-column: 8 / 17;
					margin-right: 0;
				}
			}
		}
	}
}
