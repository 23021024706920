.nav {

	/*
	 * navigation ul element
	 */
	&__list {
		position: absolute;
		right: 0;
		/*
		top: 6.8125em;
		*/
		top: var(--nav-position);
		/*
		top: 4.5rem;
		transition: all .2s ease-in-out;
		*/

		/*
		&[aria-hidden="true"] {
		*/
		/*
		&[hidden] {
			opacity: 0;
			overflow: hidden;
			visibility: hidden;
		}
		*/

		@media (--lg) {
			position: relative;
			right: unset;
			top: unset;

			/*
			 * I removed this from the markup and added
			 * a javascript rule to add aria-hidden and hidden atts
			&[aria-hidden="true"] {
				opacity: 1;
				overflow: initial;
				visibility: visible;
			}
			*/
		}

	}

	/*
	 * navigation ul > ul element
	 */
	&__sub {
		/*
		transition: all .2s ease-in-out;
		*/

		/*
		&[aria-hidden="true"] {
			opacity: 0;
			transform: translateY(-999em);
			// transition: transform .2s ease-in-out, opacity .2s ease-in-out;
		}
		*/

		@media (--lg) {
			position: absolute;
			top: 2.9375em;
			/*
			top: 3em;
			top: 2.8125em;
			top: 2.625em;
			left: 0;
			*/
			right: 0;
			left: auto;

		}

		&--login {

			border-color: #333;

			/*
			@media (--navlg) {
				right: 0;
				left: auto;
			}
			*/
		}

		&--help {

			color: #333;
			margin: .5em auto;
			padding-bottom: .5em;

			@media (--lg) {
				line-height: 1.5;
				margin: 0;
				padding-bottom: unset;
			}
		}

	}

	/*
	 * navigation toggle button
	 */
	&__bttn {

		@media (--lg) {
			@apply --sr-only;
		}
	}

}
