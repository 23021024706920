.content {

	background: #fff;
	background: hsla(0, 100%, 100%, .95);
	border-bottom: 3px solid #f6f9fc;
	margin-top: 0;

	.acnt,
	.js__controls,
	.rpt__opts,
	.rpt__list,
	.rpt__list ul {
		margin-left: 0;
	}


	&__article {

		/*
		background: #fff;
		background: hsla(0, 100%, 100%, .85);
		*/
		margin: 0 auto;
		/*
		overflow: hidden;
		*/
		padding: .5em .25em 1.5em;

		&--full {
			padding: .5em;
			width: 100%;

			@media (-med) {
				padding: .5em 0;
				max-width: var(--widthXL);
			}

			/*
			> h1 {
				margin: .5em .5em .25em;
			}
			*/
		}

		figure {
			margin: 1em .5em;
			/*
			background: #f9f9f9;
			border: 1px solid #eee;
			padding: .5em;
			*/
			position: relative;

			&.vid {
				margin: 0 .5em 1em;
			}

			img {
				min-width: 100%;
			}

			figcaption {
				line-height: 1.4;
				margin: 0 auto;
				padding: .5em;
			}

		}

		.weather {

			figure {
				margin: 0;
				padding: .25em .25em 0;
			}
		}


		h1 {
			color: #4a5680;
			font-family: var(--font-logo);
			font-size: calc(1em + 1vw);
			font-size: 2em;
			margin-top: .5em;

			@media (--med) {
				font-size: calc(1em + 1.6vw);
			}
		}

		ol,
		ul {
			margin: 0 1em 1em 1.5em;
		}

		ul {
			list-style: disc;

			&.weather {
				margin: 0;
			}

			&.nobull,
			&.js__accordian,
			&.content__prod {
				list-style: none;
				margin-left: 0;
			}

		}

		ol {
			list-style: decimal;
		}

		ul {

			&.js__accordian,
			&.content__prod {
				list-style: none;
				margin-left: 0;
			}

			&.controls {
				margin: 0;
			}
		}

		/*
		li,
		p {
			padding-right: 2.5vw;
		}
		*/

		li {
			padding: .25em 0;
		}


		/*
		p,
		li {
			max-width: 44em;
		}
		*/

		/*
		h2 {
			font-size: 2em;
		}
		*/

		h3 {
			a {
				color: #333;
				/*
				border-bottom-color: #eee;
				*/
				display: block;
				margin: 0 .5em;
			}
		}

		.readable {
			margin: .5em auto;
			/*
			margin-left: auto;
			margin-right: auto;
			*/
			max-width: 45em;
		}
	}

	&__aside {
		/*
		background: #f9f9f9;
		background: #fff linear-gradient(#f9f9f9, #fff);
		background: hsla(40, 40%, 80%, .4);
		background: linear-gradient(180deg, hsla(221, 26%, 56%, .3), hsla(221, 26%, 56%, .05));
		*/
		background: hsla(221, 26%, 56%, .05);
		margin-top: 0;
		padding: .5em .5em 1em;
		padding: 0;
		position: relative;

		&--stick {
			position: sticky;
			top: 0;
		}

		h2 {
			/*
			border-bottom: 3px solid #eee;
			*/
			background: #fff;
			background: #69c;
			color: #fff;
			font-family: var(--font-logo);
			font-size: 1.4em;
			/*
			font-weight: 600;
			*/
			margin: 0 0 .0625em;
			padding: .25em;
			text-align: center;
		}
	}


	a {
		&[href$=".docx"],
		&[href$=".doc"],
		&[href$=".pptx"],
		&[href$=".ppt"],
		&[href$=".pdf"] {
			&:after {
				color: #600;
				font-size: .8rem;
			}
		}

		&[href$=".pdf"] {
			&:after {
				content: "\0020[pdf]";
			}
		}
	}

	&__prod {

		list-style: none;
		position: relative;

		h2,
		h3 {
			font-weight: 400;
		}

		h2 {
			font-size: 1.4rem;
		}

		h3,
		caption{
			font-size: 1rem;
		}
	}

	.summary {
		/*
		background: #fcfcff;
		border: 3px dotted #69c;
		*/
		padding: 1em .5em;
		position: relative;

		h3 {
			font-size: 1em;
			margin: 0;
		}

		ul {
			margin-left: 1.5em;
		}
	}

	@media (--med) {

		align-content: stretch;
		align-items: stretch;
		display: flex;
		flex-wrap: wrap;
		justify-content: stretch;
		margin: 0 auto;
		max-width: var(--widthL);
		min-height: 75vh;


		&__article {
			flex: 5;
			padding: .25em 1em 1em;
			/*
			min-width: 36.25em;
			padding: 0 3em;
			min-height: 70vh;
			*/

			&--full {
				width: 100%;
			}
		}

		&__aside {
			flex: 2;
			min-width: 15em;
			margin-left: auto;
			padding-top: 0;
		}

	}
}

.regs {
	background: #fff linear-gradient(#f9f9f9, #fff);
	border: 3px solid #f9f9f9;
	border-radius: .25em;
	padding: 0 .5em;
	margin-bottom: 1em;

	h2 {
		color: #555;
		font-size: 1.05em;
		font-weight: 600;
	}

	ul {
		list-style: none;
	}
}

.column {

	/*
	> ul {
		list-style: none;
		margin-left: 0;
	}
	*/

	column-count: 2;

	ul {
		break-inside: avoid;
	}

	h2 {
		margin-top: 0;
	}
}

figure {
	/*
	margin: 1em 0;
	*/

	img {
		display: block;
		/*
		max-width:230px;
		max-height:95px;
		*/
		width: auto;
		height: auto;
	}

	figcaption {
		font-size: .95em;
		padding: 0 .6em;
	}

	&.hbk {
		/*
		background: #f9f9f9;
		border: 1px solid #eee;
		*/
		margin: 0 auto;
		max-width: 300px;
		padding: 1em 2em;

		img {
			display: block;
			border: 1em solid #fff;
			box-shadow: 0 2px 30px rgba(0, 0, 0, .3);
			transform: rotate(2deg);
		}

		figcaption {
			background: #fff;
			padding: 1em;
		}

		&__sb {

			background: #fff;
			padding: 1em .5em;
			text-align: center;

			a {
				border: none;
			}

			img {
				border-width: .5em;
				box-shadow: 0 2px 20px rgba(0, 0, 0, .3);
				margin: 0 auto;
			}

			figcaption {
				padding: 1em;
			}

		}

	}
}

.resp {
	display: block;
	overflow-x: auto;
	position: relative;
	/*
	width: 100%;
	*/

	table {
		font-size: .9em;
		table-layout: fixed;
		min-width: 625px;
	}

	tfoot th {
		font-weight: 400;
	}

	th,
	td {
		/*
		vertical-align: top;
		*/
		white-space: nowrap;
	}

	caption {
		font-size: 1.2em;
		text-align: left;
	}

}

.footnote {
	font-size: .8rem;
	vertical-align: super;
}

.fn {
	border-bottom-color: transparent;

	&__asterisk {
		/*
		font-size: 1.1em;
		*/
		font-weight: 400;
		padding: .25em;
	}
}

.js {

	&__accordian {
		/*
		transition: all .2s linear;
		*/

		> li {
			border-bottom: 3px solid #eee;
		}

		h2 {
			font-size: 1rem;
			margin: 0;

			button {
				align-content: flex-start;
				align-items: flex-start;
				all: inherit;
				cursor: pointer;
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				font-weight: 400;
				width: 100%;
				padding: .5rem;
				/*
				transition: all .1s linear;
				*/

				&:focus svg {
					outline: 2px solid;
				}

				svg {
					align-self: center;
					flex-shrink: 0;
					height: 1rem;
					margin-right: .5em;
					/*
					margin: 0 .5rem;
					*/
				}

				&:focus,
				&:hover {
					color: #fff;
					background: #69c;
				}
			}

			@media (--med) {
				font-size: 1.2rem;
			}
		}

		[aria-expanded=true] .vert {
			display: none;
		}

		[aria-expanded] rect {
			fill: currentColor;
		}

		/*
		&.fade {

			[aria-hidden] {
				transition: all .2s linear;
			}

			[aria-hidden="true"] {
				opacity: 0;
			}

			[aria-hidden="false"] {
				opacity: 1;
			}
		}
		*/
	}

	&__controls {

		margin: 0;
		text-align: center;

		li {
			display: inline-block;
			margin: 0;

		}

		button {
			background: #fff;
			border-color: #ccc;
			border-radius: .25em;
			margin: 0 .5em;
			padding: .25em .5em;

			&:focus {
				outline: none;
				box-shadow: 0 0 0 .25em #ccc;
			}
		}

		@media (--med) {
			position: sticky;
			text-align: right;
			top: .75em;
			z-index: 3;
		}
	}
}

/*
.phb {
	padding-bottom: 1em;
}
*/

.tblwrp {
	margin-bottom: 1em;
	max-width: 100vw;
	overflow-x: auto;
}

.extn {
	color: #600;
	font-size: .8em;
}

.stick {
	background: #fff;
	border: 3px solid #fc6;
	/*
	border-radius: .5em;
	font-size: calc(1em + .125vw);
	font-weight: 600;
	/*
	margin: 0 auto;
	*/
	padding: .5em;
	position: sticky;

	h2,
	p {
		margin: .25rem;
	}

	/*
	h2 {
		font-size: 1em;
		font-weight: 600;

		@media (--med) {
			font-size: inherit;
			font-weight: initial;
		}
	}
	*/

	&__bottom {
		bottom: 0;
	}
}
