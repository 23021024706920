:root {
	/*
	font-size: calc(1rem + .0625vw);
	*/
	--html-height: 100%;
	--html-width: 100%;
	--color-base: #fff;
	--color-text: #444;
	--color-links: #005fa9;
	--color-hlinks: #234;
	--color-logo: #fb1;
	--color-headings: #333;
	--color-banner: #1b4f7f;
	--color-bluebttn: #1c5a98;
	/*
	--color-banner: #1b3f5f;
	--color-banner: #fff;
	*/
	--color-selection: #c60;
	--color-underlight: #f6f6f6;
	--color-nav: #444;
	--color-nav-border: #f8f8f8;
	--color-ocean: hsla(210, 41%, 32%, .7);
	/*
	--color-ocean: #35597c;
	*/
	--color-royal: #003f72;
	/*
	--color-underlight: #f2f2f2;
	--color-banner: #1b4f7f;
	--color-banner: #258;
	--color-underlight: #ececec;
	--color-underdark: #222;
	--color-links: #10448c;
	--color-logo: #fd9;
	*/

	--hover-nav: #000;
	--current-nav: #930;

	--sm-fontsize: 90%;
	/*
	--fontsize: 100%;
	--lg-fontsize: 109%;
	--xl-fontsize: 120%;
	--xxl-fontsize: 133%;
	*/
	/*
	 * variable font sizing
	 */
	--fs-base: 1rem;
	--fs-mod: .1875;
	--fs-min: 20rem;
	--fs-max: 75;
	--heading-font-weight: normal;

	/*
	 * hero in <h1> of homepage
	 */
	--hero_logo_pad: 3.25em;
	--hero_logo_size: 3em;

	--mast-border: .1875em;
	--logo-width: 5em;
	--logo-margin: .25em;
	/*
	--logo-margin: .5em;
	*/
	--logo-padding: .25em;
	--nav-position: 5.575em;
	/*
	--nav-position: calc(var(--logo-width) + ((var(--mast-border) + var(--logo-margin) + var(--logo-padding)) * 2));
	*/
	/*
	 * border width
	 */

	--border-width: .25em;

	--line-height: 1.8;
	--font-norm: 400;
	--font-bold: 600;
	--font-system-sans: system-ui, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
	--font-serif: "Georgia", "Times New Roman", Times, serif;
	/*
	--font-heading: "Saira", var(--font-system-sans);
	--font-heading: "Bitter", var(--font-serif);
	--font-logo: "Saira", var(--font-system-sans);
	--font-logo: "Miriam Libre", var(--font-system-sans);
	--font-logo: "Mukta", var(--font-system-sans);
	*/
	--font-logo: "Roboto Condensed", var(--font-system-sans);
	--font-main: "Open Sans", var(--font-system-sans);
	--font-heading: "Open Sans", var(--font-system-sans);
	/*
	--font-lora: Lora, var(--font-system-sans);
	--font-caveat: Caveat, var(--font-system-sans);
	*/
	--widthM: 37.5em;
	--widthML: 50.5em;
	--widthL: 62.5em;
	--widthXL: 75em;
	--wrapper-max: var(--widthXL);

	--no-topmarg {
		margin-top: 0;
	}

	/*
	--visually-hidden-v1 {
		border: 0;
		clip: rect(0 0 0 0);
		clip-path: inset(100%);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		white-space: nowrap;
		width: 1px;
	}
	*/

	--sr-only {
		clip: rect(0 0 0 0);
		clip-path: inset(100%);
		height: 1px;
		overflow: hidden;
		position: absolute;
		white-space: nowrap;
		width: 1px;
	}

	--visually-hidden {
		border: 0;
		clip: rect(0 0 0 0);
		height: auto; /* new - was 1px */
		margin: 0; /* new - was -1px */
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
		white-space: nowrap; /* 1 */
	}
}

.fluid-type {
	--fluid-type-min-size: 1;
	--fluid-type-max-size: 2;
	--fluid-type-min-screen: 20;
	--fluid-type-max-screen: 75;
	/*
	--fluid-type-max-screen: 88;
	*/
	font-size: calc(
		(var(--fluid-type-min-size) * 1rem) + (var(--fluid-type-max-size) - var(--fluid-type-min-size)) *
		(100vw - (var(--fluid-type-min-screen) * 1rem)) /
		(var(--fluid-type-max-screen) - var(--fluid-type-min-screen))
	);
}

/*
@custom-media --landscape screen and (orientation: landscape);
*/
/* small */
@custom-media --sm screen and (min-width: 20em);
@custom-media --sm30 screen and (min-width: 30em);
/*
@custom-media --sm screen and (width >= 30em);
*/
/*
@custom-media --sm screen and (width < 30em);
@custom-media --sm_landscape screen and (width < 30em) and (orientation: landscape);
@custom-media --sm_portrait screen and (width < 30em) and (orientation: portrait);
*/
/* small - medium */
/*
@custom-media --sm_med screen and (width >= 30em) and (width < 48em);
@custom-media --sm_med_landscape screen and (width >= 30em) and (width < 48em) and (orientation: landscape);
@custom-media --sm_med_portrait screen and (width >= 30em) and (width < 48em) and (orientation: portrait);
*/
/* medium */
/*
@custom-media --med screen and (width >= 37.5em);
*/
@custom-media --med screen and (min-width: 37.5em);
@custom-media --med_landscape screen and (min-width: 37.5em) and (orientation: landscape);
@custom-media --med_portrait screen and (min-width: 37.5em) and (orientation: portrait);

/* medium - large */
@custom-media --med_lg screen and (min-width: 37.5em) and (max-width: 62.5em);
@custom-media --med_lg_landscape screen and (min-width: 48em) and (max-width: 62.5em) and (orientation: landscape);
@custom-media --med_lg_portrait screen and (min-width: 48em) and (max-width: 62.5em) and (orientation: portrait);
/* medium - xlarge */
@custom-media --med_xl screen and (min-width >= 37.5em) and (max-width: 68.75em);
@custom-media --med_xl_landscape screen and (min-width: 37.5em) and (max-width: 68.75em) and (orientation: landscape);
@custom-media --med_xl_portrait screen and (min-width: 37.5em) and (max-width: 68.75em) and (orientation: portrait);
/* large */
/*
@custom-media --lg screen and (width >= 62.5em);
*/
@custom-media --lg screen and (min-width: 62.5em); /* 1000px */
@custom-media --lg_landscape screen and (min-width: 62.5em) and (orientation: landscape);
@custom-media --lg_portrait screen and (min-width: 62.5em) and (orientation: portrait);

/* lg - xl */
@custom-media --lg_xl screen and (min-width: 62.5em) and (max-width: 75em);
/*
@custom-media --lg_xl screen and (width >= 62.5em) and (width < 68.75em);
@custom-media --lg_xl_landscape screen and (width >= 62.5em) and (width < 80em) and (orientation: landscape);
@custom-media --lg_xl_portrait screen and (width >= 62.5em) and (width < 80em) and (orientation: portrait);
*/
/*
@custom-media --lg_xl screen and (width >= 62.5em) and (width < 72.9375em);
@custom-media --lg_xl_landscape screen and (width >= 62.5em) and (width < 72.9375em) and (orientation: landscape);
@custom-media --lg_xl_portrait screen and (width >= 62.5em) and (width < 72.9375em) and (orientation: portrait);
*/
/* extra large */
/*
@custom-media --xl screen and (width >= 68.75em);
*/
@custom-media --xl screen and (min-width: 75em);
@custom-media --xxl screen and (min-width: 125em);

/* navigation */
@custom-media --navlg screen and (min-width: 71em);
@custom-media --navxl screen and (min-width: 97em);
/* nav tweak */
/*
@custom-media --navbp screen and (width >= 56.25em);
*/

@custom-media --print print;
