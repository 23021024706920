body {

	align-content: stretch;
	align-items: stretch;
	display: flex;
	flex-direction: column;
	z-index: 0;
}

.banner,
.footer,
main {
	width: 100%;
}

main {
	/*
	background: fixed linear-gradient(180deg, #4b7fae, #fff);;
	*/
	background: linear-gradient(180deg, #4b7fae, #fff);;
	flex: 1;
	z-index: 1;
}

.home main {
	background: #fff;
}

.footer {
	margin-top: auto;
}
