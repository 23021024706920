*:focus:not(:focus-visible),
*:before:focus:not(:focus-visible),
*:after:focus:not(:focus-visible) {
	outline: none;
}

.plain-list {
	list-style: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E");
	padding-left: 0;
}

/* prettier-ignore */
@media (prefers-reduced-motion: reduce) {
	*,
	*:before,
	*:after {
		transition: none !important;
		animation: none !important;
		scroll-behavior: auto !important;
	}
}

.vh {
	@apply --visually-hidden;
	/*
	@apply --sr-only;
	*/
}

/*
.vh {
	@media (--lg) {
		&_lg {
			@apply --visually-hidden;
		}
	}
}
*/

.a11y {

	&__skip {
		clip: rect(1px 1px 1px 1px);
		height: 1px;
		overflow: hidden;
		position: absolute;
		transform: translateY(-100%);
		/*
		transition: transform .5s cubic-bezier(.18, .89, .32, 1.28);
		*/
		transition: transform .125s ease-in-out;
		white-space: nowrap;
		width: 1px;

		&:focus {
			background-color: #000;
			border: 1px solid var(--color-logo);
			clip: auto;
			color: #fff;
			font-size: 1em;
			height: auto;
			left: 50%;
			margin: 0 0 0 -100px;
			outline: 0;
			overflow: visible;
			padding: .8em 0 .7em;
			position: fixed;
			text-align: center;
			text-decoration: none;
			top: .3em;
			transform: translateY(0%);
			white-space: normal;
			width: 200px;
			z-index: 3;
		}
	}
}

/*
[role="alert"] {
}
*/

/*
.vhlg {
	@media (--med) {
		@apply --visually-hidden;
	}
}
*/

/* have screen reader pause before an abbreviation */
/*
.at-pause::after {
	content: ".";
	opacity: 0;
}
*/
