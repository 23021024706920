.icon {
	/*
	*/
	fill: none;
	height: 1.25em;
	margin: 0 auto;
	stroke: currentColor;
	stroke-width: 1;
	stroke-linecap: round;
	stroke-linejoin: miter;
	vertical-align: middle;
	width: 1.25em;
	/*
	fill: transparent;
	width: 48px;
	height: 48px;
	stroke: #2329D6;
	color: #2329D6;
	*/

	&__clipboard,
	&__file {
		stroke-width: 2;
	}

	&__list {
		/*
		background: #333;
		*/
		fill: #003f72 !important;
		stroke: var(--color-banner);
	}

	/*
	&__weather {
		display: block;
		color: var(--color-banner);
		fill: #ffffef;
		height: 6em;
		stroke: var(--color-banner);
		width: 6em;
	}
	*/

	&__incoming {
		height: 4em;
		width: 4em;
	}

	&__info {
		stroke-width: 1.5;
		height: 2em;
		width: 2em;
	}
}

.icon + h2 {
	margin: -.25em 0 1em;
}

h2 + .icon {
	margin: .5em auto 2em;
}
