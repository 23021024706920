/* miriam-libre-regular - latin */
/*
@font-face {
	font-family: "Miriam Libre";
	font-style: normal;
	font-weight: 400;
	src: local("Miriam Libre Regular"), local("MiriamLibre-Regular"),
		url("../fonts/miriam-libre-v5-latin-regular.woff2") format("woff2"),
		url("../fonts/miriam-libre-v5-latin-regular.woff") format("woff");
}
*/

/* miriam-libre-700 - latin */
/*
@font-face {
	font-family: "Miriam Libre";
	font-style: normal;
	font-weight: 700;
	src: local("Miriam Libre Bold"), local("MiriamLibre-Bold"),
		url("../fonts/miriam-libre-v5-latin-700.woff2") format("woff2"),
		url("../fonts/miriam-libre-v5-latin-700.woff") format("woff");
}
*/

/*
 * check out Roboto Condensed
 */

/* open-sans-regular - latin */
@font-face {
	font-display: swap;
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	src: local("Open Sans Regular"), local("OpenSans-Regular"),
		url("../fonts/open-sans-v16-latin-regular.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */
		url("../fonts/open-sans-v16-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-700 - latin */
@font-face {
	font-display: swap;
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 700;
	src: local("Open Sans Bold"), local("OpenSans-Bold"),
		url("../fonts/open-sans-v16-latin-700.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */
		url("../fonts/open-sans-v16-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* mukta-500 - latin */
/*
@font-face {
	font-family: "Mukta";
	font-style: normal;
	font-weight: 500;
	src: local("Mukta Medium"), local("Mukta-Medium"),
		url("../fonts/mukta-v6-latin-500.woff2") format("woff2"),
		url("../fonts/mukta-v6-latin-500.woff") format("woff");
}
*/

/* roboto-condensed-regular - latin */
@font-face {
	font-display: swap;
	font-family: "Roboto Condensed";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto Condensed"), local("RobotoCondensed-Regular"),
		url("../fonts/roboto-condensed-v17-latin-regular.woff2") format("woff2"),
		url("../fonts/roboto-condensed-v17-latin-regular.woff") format("woff");
}

/* roboto-condensed-700 - latin */
@font-face {
	font-display: swap;
	font-family: "Roboto Condensed";
	font-style: normal;
	font-weight: 700;
	src: local("Roboto Condensed Bold"), local("RobotoCondensed-Bold"),
		url("../fonts/roboto-condensed-v17-latin-700.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */
		url("../fonts/roboto-condensed-v17-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
