.nav__login {

	button {
		border: 1px solid transparent;
		outline: 0;
		padding: .5em 1em;
	}
}

.butt {
	padding: .5em 1em;
	background: #fff;
	/*
	border: 1px solid rgba(0, 0, 0, .1);
	border-radius: .2em;
	*/
	/*
	background: #fff linear-gradient(hsla(0, 0%, 100%, .1), hsla(0, 0%, 0%, .1));
	font-size: inherit;
	*/
	box-shadow: 0 .05em .25em rgba(0, 0, 0, .15);
	color: #333;
	cursor: pointer;
	/*
	display: block;
	*/
	font-weight: 600;
	line-height: 1.2;
	margin: 0 auto;
	text-align: center;
	/*
	transition: .1s all;
	white-space: nowrap;
	*/
	/*
	width: 100%;
	*/

	&:focus,
	&:hover {
		outline: .25em solid;
		outline-color: #cde;
		outline-offset: .25em;
		/*
		outline-color: #cde;
		background-image: linear-gradient(hsla(0, 0%, 100%, .3), hsla(0, 0%, 0%, .3));
		*/
	}

	/*
	&__attn {
		background: #ff6347;
		color: #fff;
		padding: .75em 1em;
	}
	*/

	&__covid {
		margin: 0;
		text-align: center;

		li {
			padding: .25em;
		}
	}

	&__notice {
		background: #222;
		border: none;
		color: #fd3;
		font-size: 1.2em;
		padding: 1em 1.5em;
	}

	&__royal {
		background: #003f72;
		color: #fff;
		padding: .75em 1em;
	}

	&__blue {
		background: #38c;
		background: var(--color-bluebttn);
		color: #fff;
	}

	&__dkblue {
		background: var(--color-banner);
		color: #fff;
	}

	&__green {
		background: #4b9e40;
		color: #fff;
	}

	&__caution {
		background: #f6a800;
		color: #fff;
	}

	&__white {
		background: #fff;
		color: #c60;

		&:focus,
		&:hover {
			background-color: var(--color-banner);
			color: #fff;
		}
	}

	&__updt {
		background: var(--color-banner);
	}

	&__up {
		background: #fff;
		font-size: .9em;
		padding: .25em .5em;
		/*
		bottom: 10em;
		position: fixed;
		right: 0;
		z-index: 30;
		*/
		/*
		&:focus,
		&:hover {
			background: #fff;
		}
		*/
	}

	&__closer {
		color: #800;
		position: fixed;
		top: 1em;
		right: 1.5em;
		&:focus,
		&:hover {
			outline-color: #800;
		}

	}

	&.working {
		background: #eee;
		color: #999;
	}

	@media (--med) {
		&__lm {
			margin-left: 1em;
		}

		&__rm {
			margin-right: 1em;
		}
	}

	@media (--sm30) {
		/*
		display: initial;
		width: auto;
		*/
	}
}

button.butt {
	padding: .66em 1em;
}

a.butt {
	display: block;
	margin-bottom: .25em;
	margin-top: .25em;

	@media (--med) {
		display: inline-block;
	}
}

/*
.bttn {
	text-decoration: none;

	&__submit {
		border-color: #eee;
	}

	&__rdmr {
		background-color: #fff;
		color: var(--color-banner);
		border: var(--border-width) #eeb95f;
		border-style: solid;
		border-radius: var(--border-width);
		display: block;
		font-weight: 700;
		font-variant: small-caps;
		margin: .25em auto;
		text-transform: lowercase;

		&:hover,
		&:focus {
			border-color: var(--color-logo);
			color: #fff;
			background: color(var(--color-banner) l(35%));
		}

	}

}
*/
