/*
 * default styles
 */

* {
	color: inherit;
	font-family: inherit;
	line-height: inherit;
}

* + * {
	margin-top: 1em;
}

/*
 * exceptions to the lobotimized owl above
 */
.nav__login,
.nav__toggle,
.rpt__flex select,
.vid iframe,
body,
select,
br,
li,
dt,
dd,
th,
td,
option,
main,
div,
a,
span,
img,
figcaption,
header,
nav {
	margin-top: 0;
}

body,
html {
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
}

html {
	box-sizing: border-box;
	font-family: var(--font-main);
	font-size: var(--fs-base);
	scroll-behavior: smooth;
}

*,
:after,
:before {
	box-sizing: inherit;
}

svg:not(:root) {
	overflow: hidden;
}

body {
	margin: 0;
	background-color: var(--color-base);
	color: var(--color-text);
	line-height: var(--line-height);
	/*
	 * hack for overflow issues
	 * overflow-x: hidden;
	 */
	overflow-y: scroll;

	&.noscroll {
		overflow-y: hidden;
	}

	&.fluid-type {
		--fluid-type-min-size: 1;
		--fluid-type-max-size: 1.1875;
	}
}

h1,
h2,
h3,
h4 {
	color: var(--color-headings);
	font-family: var(--font-headings);
	font-weight: 400;
	line-height: 1.4;
	margin: 1em 0 .5em;
	/*
	margin-top: 1em;
	*/
}

h1 {
	font-size: 2rem;
	/*
	@media (--lg) {
		font-size: 2.2em;
	}
	*/
}

h2 {
	font-size: 1.5rem;
	/*
	@media (--lg) {
		font-size: 1.6em;
	}
	*/
}

h3 {
	font-weight: 600;
	font-size: 1.1rem;
	/*
	@media (--lg) {
		font-size: 1.3em;
	}
	*?
}

h4 {
	font-weight: var(--font-bold);
	font-size: 1em;
	/*
	@media (--lg) {
		font-size: 1.1em;
	}
	*/
}

p {
	margin-bottom: 1em;
}

hr {
	border-bottom: 1px solid #ccc;
	box-sizing: content-box;
	height: 0;
	margin: 1em auto;
	max-width: var(--widthM);
	overflow: visible;
	padding: 0;
}

i,
em {
	font-style: italic;
}

b,
strong {
	font-weight: var(--font-bold);
}

::-moz-selection {
	color: var(--color-base);
	background: var(--color-selection);
	text-shadow: none;
}

::selection {
	color: var(--color-base);
	background: var(--color-selection);
	text-shadow: none;
}

a {
	border: 1px solid;
	border-color: transparent transparent currentColor;
	color: var(--color-links);
	text-decoration: none;
	/*
	padding-bottom: 2px;
	transition: all .25s;
	*/

	&:focus {
		border-color: currentColor;
		border-style: dotted;
		/*
		border: 1px dashed;
		border-style: solid;
		*/
	}

	&:hover {
		/*
		border-style: solid;
		border-color: transparent;
		*/
		border-color: transparent transparent #c0d8ee;;
	}
}

*:focus {
	outline: none;
}
