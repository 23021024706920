/*
[type="email"],
[type="password"],
[type="text"],
textarea {
	background-color: #eee;
}
*/

.nav__sub {

	&--login {

		background: #fff;
		padding: 0 .5em;
		text-align: left;
		width: auto;

		/*
		 * holds form imputs
		 */
		span {
			padding: 1em .0625em 0;
			position: relative;
		}

		li {
			padding: 0 0 1em;
		}

		/*
		text-align: center;
		width: 100%;
		*/
		button,
		[type="email"],
		[type="password"] {
			padding: .25em .5em;
		}

		label,
		button {
			font-variant: small-caps;
			font-weight: 600;
		}

		[type="email"],
		[type="password"] {
			color: #345;
			/*
			background: #3c8ac8;
			border: 1px solid #1c6aa8;
			*/
			background: #fff;
			border: 1px solid #ccc;
			margin: 0;
			/*
			margin: 1em .0625em 0;
			*/
			min-width: 100%;

			&:focus {
				/*
				background: #fff;
				border-color: #ccc;
				*/
				color: #333;
			}
		}

		button {
			background-color: #003f72;
			border: 1px solid #000;
			color: #fff;
			display: block;
			margin: 1em .0625em 0;
		}

		input {
			padding: .25em .5em;
		}


		label {

			color: #333;
			display: inline-block;
			font-weight: 600;
			position: relative;

			&.err__field {
				background: rgba(0, 0, 0, .1);
			}

			&.in__el {
				/*
				color: #333;
				color: #fff;
				display: unset;
				*/
				line-height: 1;
				padding: 0;
				position: absolute;
				top: 1.6em;
				left: .5em;
				transition: .25s ease;

				&.in__foc {
					/*
					color: #666;
					*/
					left: .5em;
					top: -.125em;

					/*
					&.req {
						&::after {
							content: "";
						}
					}
					*/
				}
			}
		}

		/*
		@media (--navlg) {
		*/
		@media (--lg) {

			/*
			[type="email"] {
				border-right: 1px solid transparent;
			}
			*/

			button {
				padding: .25em 1em;
			}
		}
	}

	&--help {

		padding: .25em 1em;
		text-align: center;

		.cont {
			background: none;
			display: inline;
			margin: none;
			padding: 0;

			&:focus,
			&:hover {
				background: none;
				color: currentColor;
			}
		}
	}

	&--fields {

		align-content: center;
		align-items: center;
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		margin: 0 auto;
		width: 100%;

		@media (--med) {
			flex-direction: row;
			position: relative;
		}
	}
}

.no-js {

	.nav__login label {
		color: #666;
		text-align: left;
	}
}
