.c {
	text-align: center;

	&__t {
		text-align: center;
	}

	&__m {
		margin: 0 auto;
	}
}

.pt {
	padding-top: 1em;
}

.r {
	text-align: right;
}

@media (--med) {
	.nobr {
		white-space: nowrap;
	}
}

kbd {
	background: #f9f9f9;
	border: 1px solid #e9e9e9;
	border-radius: .25em;
	color: #039;
	font-family: monospace;
	padding: 0 .125em;
}
