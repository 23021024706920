.banner {
	align-content: center;
	align-items: center;
	justify-content: space-between;
	display: flex;
	/*
	 * flex-shrink: 0;
	 * this is necessary for Safari/webkit browsers
	 */
	flex-shrink: 0;
	padding: 0 1em 0 .25em;
	z-index: 2;
	/*
	*/

	*:focus {
		border: 1px solid #ccc;
		outline: unset;
	}

	@media (--med-lg) {
		padding: 0 6vw;
	}

	@media (--lg) {
		justify-content: center;
		flex-wrap: wrap;
		margin: 0 auto;
		max-width: var(--widthL);
		padding: 0;
	}

	&__img {
		display: block;
		/*
		 * add height for safari
		height: 80px;
		width: 80px;
		*/
		/*
		width: var(--logo-width);
		*/
	}

	&__home--link {
		border: 1px solid transparent;
		/*
		display: inline-block;
		*/
		margin: var(--logo-margin);

		&:hover {
			border-color: transparent;
		}

		@media (--lg) {
			margin-right: auto;
		}
	}

}
